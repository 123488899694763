import React, { useState } from "react";
import { useParams } from "react-router";
import { capitalize } from "lodash";
import { Button, Badge } from "@unite-us/app-components";
import { useFeeScheduleScreenings } from "api/core/feeScheduleScreeningHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import { stateBgColor } from "utils/stateBgColor";
import { textColorForStyleProp } from "utils/stateBgColor";
import moment from "moment";

const FeeScheduleScreenings = () => {
  const { feeScheduleId } = useParams();
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data: screeningsResponse,
    isFetching,
    isError,
  } = useFeeScheduleScreenings({
    filters: { fee_schedule: feeScheduleId },
    pageSize,
    pageNumber,
  });
  const { data: screenings, paging } = screeningsResponse || {};

  const tableHeaders = [
    { label: "Name", className: "w-1/4" },
    { label: "State", className: "w-1/4" },
    { label: "Start Date", className: "w-1/4" },
    { label: "End Date", className: "w-1/4" },
  ];

  const tableBody = screenings?.map((screening) => ({
    rowId: screening.id,
    rowData: [
      { data: screening.name },
      {
        data: (
          <Badge
            className={`!leading-4 !text-sm px-4 pt ${stateBgColor(screening.state)}`}
            text={capitalize(screening.state)}
            color=""
            style={textColorForStyleProp(screening.state)}
          />
        ),
      },
      { data: moment.utc(screening.starts_at).format("LL") },
      { data: moment.utc(screening.ends_at).format("LL") },
    ],
  }));

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue">
        <h1 className="text-xl content-center">Fee Schedule Screenings</h1>
        <div className="flex flex-row space-x-4">
          <Button
            id="add-screening-button"
            icon={{ name: "Add", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Add Screening"
            data-testid="add-screening-button"
            onClick={() => {}}
            disabled
          />
        </div>
      </div>
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        dataTestId="fee-schedule-screenings-table"
        onRowClick={() => {}}
        emptyTableMessage="No Results Found."
        emptyTableClassName="max-h-40"
        errorMessage="Error fetching Screenings."
        className={tableBody?.length !== 0 ? "!h-fit" : ""}
      />
    </>
  );
};

export default FeeScheduleScreenings;
